import { CLEAR_DATA, CLEAR_PATIENT_DETAILS, CLEAR_SEARCH_STRING, CLEAR_USER, LOGOUT_USER, SET_AUTH_USER, SET_DATA, SET_PATIENT_DETAILS, SET_RND, SET_SEARCH_STRING } from "../actions/types";

const INIT_STATE = {
  isAuthenticated: false,
  user: {},
  rnd: 0,
  searchString: '',
  patientDetails: {}
};

export default (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_RND: {
      return {
        ...state,
        rnd: Math.random()
      };
    }



    case SET_AUTH_USER: {
      return {
        ...state,
        user: payload
      };
    }

    case CLEAR_USER: {
      return {
        ...state,
        user: {}
      };
    }


    case LOGOUT_USER: {
      return {
        ...state,
        user: {},
        isAuthenticated: false
      };
    }

    case SET_DATA: {
      return {
        ...state,
        data: payload
      };
    }
    case CLEAR_DATA: {
      return {
        ...state,
        data: []
      };
    }
    case SET_DATA: {
      return {
        ...state,
        data: payload
      };
    }
    case CLEAR_DATA: {
      return {
        ...state,
        data: []
      };
    }
    case SET_PATIENT_DETAILS: {
      return {
        ...state,
        patientDetails: payload
      };
    }
    case CLEAR_PATIENT_DETAILS: {
      return {
        ...state,
        patientDetails: {}
      };
    }
    case SET_SEARCH_STRING: {
      return {
        ...state,
        searchString: payload
      };
    }
    case CLEAR_SEARCH_STRING: {
      return {
        ...state,
        searchString: ''
      };
    }
    default:
      return state;
  }
};
